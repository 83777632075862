$black: #000;
$red: #dc3545;
$gray-100: #efefef;
$gray-900: #54595F;
$text-color: #000;

$font-family-sans-serif: "pf_din_mono_vnregular", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$navbar-nav-link-padding-x: 1.5rem;
$navbar-light-color: $black;
$navbar-light-active-color: #8e8e8e;
$navbar-light-hover-color: $black;
$light: transparent;

$input-btn-focus-width: 0;
$btn-box-shadow: none;
$input-btn-focus-box-shadow: none;
$border-radius: 0;
$primary: $black;
$input-btn-padding-y: .5rem;
$input-btn-padding-x: 1.75rem;

// $headings-font-family: "pf_din_mono_vnregular", Sans-serif;

$paragraph-margin-bottom: 1.5rem;
$form-group-margin-bottom: 1.5rem;
$custom-control-label-color: #929292;
$link-hover-decoration: none;
$input-placeholder-color: #ccc;

$grid-gutter-width: 0px !default;
