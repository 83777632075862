@font-face {
    font-family: 'ibm_plex_sansbold';
    src: url('../fonts/ibmplexsans-bold.eot');
    src: url('../fonts/ibmplexsans-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ibmplexsans-bold.woff2') format('woff2'),
         url('../fonts/ibmplexsans-bold.woff') format('woff'),
         url('../fonts/ibmplexsans-bold.ttf') format('truetype'),
         url('../fonts/ibmplexsans-bold.svg#ibm_plex_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ibm_plex_sansregular';
    src: url('../fonts/ibmplexsans-regular.eot');
    src: url('../fonts/ibmplexsans-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ibmplexsans-regular.woff2') format('woff2'),
         url('../fonts/ibmplexsans-regular.woff') format('woff'),
         url('../fonts/ibmplexsans-regular.ttf') format('truetype'),
         url('../fonts/ibmplexsans-regular.svg#ibm_plex_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pepper_monobold';
    src: url('../fonts/peppermono-bold.eot');
    src: url('../fonts/peppermono-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/peppermono-bold.woff2') format('woff2'),
         url('../fonts/peppermono-bold.woff') format('woff'),
         url('../fonts/peppermono-bold.ttf') format('truetype'),
         url('../fonts/peppermono-bold.svg#pepper_monobold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pf_din_mono_vnbold';
    src: url('../fonts/pfdinmonovn-bold.eot');
    src: url('../fonts/pfdinmonovn-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pfdinmonovn-bold.woff2') format('woff2'),
         url('../fonts/pfdinmonovn-bold.woff') format('woff'),
         url('../fonts/pfdinmonovn-bold.ttf') format('truetype'),
         url('../fonts/pfdinmonovn-bold.svg#pf_din_mono_vnbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pf_din_mono_vnregular';
    src: url('../fonts/pfdinmonovn-regular.eot');
    src: url('../fonts/pfdinmonovn-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pfdinmonovn-regular.woff2') format('woff2'),
         url('../fonts/pfdinmonovn-regular.woff') format('woff'),
         url('../fonts/pfdinmonovn-regular.ttf') format('truetype'),
         url('../fonts/pfdinmonovn-regular.svg#pf_din_mono_vnregular') format('svg');
    font-weight: normal;
    font-style: normal;

}