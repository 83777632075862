.ql-font-pf_din_mono_vnregular {
  font-family: "pf_din_mono_vnregular";
}

.ql-font-pepper_monobold {
  font-family: "pepper_monobold";
}

#toolbar-container .ql-font span[data-label="pf_din_mono_vnregular"]::before {
  font-family: "pf_din_mono_vnregular";
}

#toolbar-container .ql-font span[data-label="pepper_monobold"]::before {
  font-family: "pepper_monobold";
}

.ql-container {
  font-family: "pf_din_mono_vnregular";
}

ul {
  list-style: none;
}

.row {
  align-items: flex-start;
}

.btn{
  font-family: "pf_din_mono_vnregular", Sans-serif;
}

.btn-primary:focus, .btn-primary.focus{
  box-shadow: none;
}

.mt-6{
  margin-top: 5rem;
}

.mb-6{
  margin-bottom: 5rem;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
}

.main, .pp-container{
  p, li, span {
    color: #000;
  }
  @include media-breakpoint-up(md) {
    p, li {
      text-justify: auto;
      text-align: justify;
    }
  }
}

.btn {
  span {
    color: #fff;
  }
}

.btn.btn-contact {
  span {
    color: #000;
  }
}

.content-right{
  a{
    color: $gray-900;
    &:hover{
      color: #000;
    }
  }

}

.form-group{
  label{
    font-size: 1.25rem;
  }
  .form-text{
    display: block;
    margin-top: .75rem;
  }
  &.error{
    .form-control{
      border-color: $red;
    }
  }
}

.form-control{
  padding-left: .75rem;
  padding-right: .75rem
}

.navbar-collapse.collapsing,
.navbar-collapse.show {
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  z-index: 1;
  align-items: flex-end;
  padding-right: 32px;

  &:before{
    content: "";
    display: block;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.85);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 0;
  }
  span {
    color: white;
  }
}

.navbar {
  padding: 0;
  justify-content: flex-start;
  .active .nav-link span:after{
    background-color: #8e8e8e;
  }
}

.nav-brand{
  margin-top: 25px;
  margin-left: 20px;
  @include media-breakpoint-up(lg) {
    margin-top: 40px;
    margin-left: 8.33333%;
  }
  img{
    width: 100px;
    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }
  &.nav-brand-home {
    margin-top: 15px;
    margin-left: 15px;
    @include media-breakpoint-up(lg) {
      margin-top: 33px;
      margin-left: 8.33333%;
    }
    img {
      width: 40%;
    }
  }
  &.nav-brand-overlay {
    z-index: 2;
    position: fixed;
    margin-top: 0;
    top: 15px;
  }
}

.nav-var {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    position: absolute;
    right: 8.33333%;
    top: 40px;
    flex-direction: row;
  }
}

.nav-var.nav-var-mobile {
  position: fixed;
  top: 19%;
  left: 15px;
  text-align: left;
  align-items: flex-start;
  padding-left: 0;
  .nav-item {
    margin: 0;
    font-size: 1.8rem;
  }
  .nav-link {
    padding: 0;
    font-size: 1.8rem;
  }
  .lang-section {
    font-size: 1.2rem;
    .nav-link {
      font-size: 1.2rem;
    }
  }
}

.nav-item {
  margin: 8px 0;
  @include media-breakpoint-up(lg) {
    margin-left: 10%;
  }
  &.nav-item-vi {
    @include media-breakpoint-up(lg) {
      margin-left: 5%;
    }
  }
}
.nav-link {
  white-space:nowrap;
  font-size: 1.1rem;
  padding: 8px 0;
  color: white;
  @include  media-breakpoint-up(lg) {
    color: black;
  }
  &.active{
    color: #ccc;
    @include media-breakpoint-up(md) {
      color: #8e8e8e;
    }
  }
  span{
    position: relative;
    &:after{
      position: absolute;
      bottom: -30%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ccc;
      opacity: 0;
      transition: all 0.25s ease-out;
      transform: translateY(-10px);
      content: "";
      @include media-breakpoint-up(md) {
        background-color: black;
      }
    }
  }
  &:hover{
    span:after{
      height: 2px;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.lang-section {
  z-index: 1;
  justify-content: center;
  align-items: center;
  &.lang-section-vi {
    margin-right: 0;
  }
  span {
    margin: -2px 2px 0;
  }
  .nav-link{
    display: inline-block;
    &.nav-link-light {
      color: #fff;
    }
    &:focus,
    &.active{
      color: #5e5e5e;
      //@include media-breakpoint-up(lg) {
      //  color: #ccc;
      //}
    }
  }
}

.navbar-toggler{
  padding: 0;
  border: none;
  position: fixed;
  top: 20px;
  right: 15px;
  z-index: 10;
  @include media-breakpoint-up(lg) {
    top: 33px;
    right: 33px;
  }
  &:focus{
    outline: none;
  }
  &[aria-expanded="true"]{
    z-index: 10;
    margin-top: 6px;
    .second{
      display: none;
    }
    .first{
      transform: rotate(-45deg);
    }
    .third{
      transform: rotate(45deg);
      margin-top: -9px;
    }
    span {
      background-color: white;
    }
  }
  span{
    height: 2px;
    width: 30px;
    border-radius: .25rem;
    margin-bottom: .45rem;
    margin-top: .45rem;
    display: block;
    position: relative;
    background-color: black;
  }
}

.navbar.nav-light {
  .lang-section {
    color: white;
  }
  .nav-link {
    color: white;
    &.active{
      color: #7e7e7e;
    }
    &:hover{
      color: #ccc;
    }
    span:after {
      @include media-breakpoint-up(md) {
        background-color: white;
      }
    }
  }
  .navbar-toggler {
    span {
      background-color: white;
    }
  }
}

.footer {
  background: #ccc;
  color: #000;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    padding: 5rem 8.33333% 2rem;
    justify-content: space-between;
  }
  > div {
    flex: 1;
    margin-bottom: 10px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .contact-address {
    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }
  .copyright {
    @include media-breakpoint-up(lg) {
      text-align: right;
    }
  }
}

.footer-float{
  display: flex;
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0;
  .btn {
    font-size: 0.9rem;
    padding: 0.3rem 0.5rem;
    @include media-breakpoint-up(lg) {
      padding: 0.5rem 1.75rem;
      font-size: 1rem;
    }
  }

  padding: 1rem;
  @include media-breakpoint-up(lg) {
    justify-content: space-between;
    padding: 1.5rem 8.33333%;
  }
  >div{
    &:first-child {
      flex: 1;
    }
    &:last-child {
      flex: 1;
      text-align: right;
    }
  }
  .socials{
    margin-top: auto;
    margin-bottom: auto;
    @include media-breakpoint-up(lg) {
      margin-top: .5rem;
    }
    a{
      display: inline-block;
      margin-left: 1.25rem;
      &:first-child{
        margin-left: 0;
      }
    }
  }
  .scroll-top{
    display: block;
    margin-top: .5rem;
    text-align: center;
    &:hover{
      text-decoration: none;
      color: #8e8e8e;
    }
  }
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.footer-float.show-fixed {
  transition: opacity 1s ease-in;
  opacity: 1;
  height: auto;
}

.footer-float.show-bottom {
  opacity: 1;
  height: auto;
  position: static;
}

.pl-p4 {
  @include media-breakpoint-up(lg) {
    padding-left: 4.16665%;
  }
}
.pr-p4 {
  @include media-breakpoint-up(lg) {
    padding-right: 4.16665%;
  }
}

.pp-container {
  padding-left: 15px;
  padding-right: 15px;
  @include media-breakpoint-up(lg) {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }
}

.mt-p18 {
  margin-top: 18%;
}

h2 {
  font-size: 1.3rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }
}

.scroll-top-div {
  display: flex;
  align-items: center;
}

.text-danger > span {
  color: #f00;
}