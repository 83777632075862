@import "fonts";
@import "variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/quill/dist/quill.snow";
@import "common";

.vi {
  .home {
    h1 {
      font-size: 2.6rem;
    }
  }
}
.home{
  background: url(/assets/pages/bg-home.jpg) no-repeat 50%;
  background-size: cover;
  height: 100vh;
  position: relative;
  z-index: 1;
  &:before{
    content: "";
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0,0,0,.35);
    position: absolute;
    z-index: 0;
  }

  .navbar-collapse.collapsing:before, .navbar-collapse.show:before{
    background: rgba(0,0,0,.85);
  }

  .navbar-light .navbar-toggler span{
    background: #fff;
  }

  h1, h1 > span {
    font-family: "pepper_monobold";
    font-size:  2rem;
    color: #fff;
    position: relative;
    @include media-breakpoint-up(md) {
      font-size:  3rem;
    }
  }
  .main{
    position: fixed;
    top: 22%;
    left: 8.33333%;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    .social-networks {
      display: none;
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
      }
    }
    .sn-item {
      margin: 8px 0;
    }
    @include media-breakpoint-up(lg) {
      width: 83.33334%;
    }
  }
  .footer {
    position: fixed;
    width: 100%;
    bottom: 20px;
    color: #fff;
    padding: 0;
    background: none;
    left: 15px;

    font-size: 0.96rem;
    @include media-breakpoint-up(xl) {
      font-size: 1rem;
    }
    > div {
      margin-top: 5px;
      @include media-breakpoint-up(lg) {
        margin-right: 6%;
      }
    }
    @include media-breakpoint-up(lg) {
      left: 8.33333%;
    }
    a{
      color: #fff;
      &:hover{
        color: #000;
      }
    }
    .contact-address {
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }
    .copyright {
      @include media-breakpoint-up(lg) {
        right: 8.33333%;
        position: fixed;
        bottom: 23px;
      }
    }
  }
}

.main{
  margin-top: 18%;
  padding-left: 15px;
  padding-right: 15px;
  @include media-breakpoint-up(lg) {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }
  h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .page-banner{
    margin-left: -15px;
    margin-right: -15px;
    @include media-breakpoint-up(md) {
      margin-left: -2.85rem;
      margin-right: -2.85rem;
    }
    img{
      width: 100%;
    }
  }
  .service-list{
    list: none;
    padding: 0;
    margin: 0;
    li{
      display: flex;
      justify-content: space-between;
      //align-items: top;
      margin-bottom: 2.5rem;
      p{
        margin-bottom: 0;
      }
      >span{
        font-size: 2.5rem;
        line-height: 1;
        display: inline-block;
        margin-right: 2.5rem;
      }
    }
  }
}

.main-project{
  .filter{
    margin-bottom: 46px;
    margin-left: -1.25rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
    a{
      display: inline-block;
      margin: 0 1.25rem;
      color: #7A7A7A;
      position: relative;
      &:hover, &.active{
        color: $black;
        text-decoration: none;
      }
      >div>span{
        position: absolute;
        font-size: .65rem;
        right: -.75rem;
        top: 0;
      }
    }
  }

  .projects {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .project-item{
    position: relative;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 48%;
    }
    a{
      display: block;
    }
    &:hover{
      .project-caption{
        // transform: translate3d(0,0,0);
        // bottom: -1px;
      }
      .project-img{
        transform: scale(1.1);
        filter: none;
      }
    }
    .project-img-wrapper{
      overflow: hidden;
    }
    .project-caption{
      padding: 1em 0em 0 0em;

      .location {
        font-size: 0.8125rem;
        @include media-breakpoint-up(lg) {
          font-size: 1rem;
        }
      }
    }
  }

}

.project-img{
  width: 100%;
  height: 230px;
  object-fit: cover;
  display: block;
  transition: all .3s ease-in-out;
  @include media-breakpoint-up(md) {
    height: 250px;
  }
  @include media-breakpoint-up(lg) {
    height: 360px;
  }
}

.contact-form .btn-submit{
  padding: 0;
  background: none;
  margin: 0;
  text-decoration: underline;
  border: none;
  color: #000000;
  &:focus{
    background: none;
  }

}
.contact-info{
  color: #000000;
  a{
    color: $gray-900;
    &:hover{
      color: $black;
      text-decoration: none;
    }
  }
  li{
    margin-bottom: 1.25rem;
    display: flex;
    span img{
      opacity: .5;
      margin-right: 1rem
    }
  }
}

.main-contact{
  p, li{
    font-weight: 400;
  }
  .social-networks {
    display: flex;
    flex-direction: column;
  }
}


.img-render{
  img{
    max-width: 100%;
  }
}

.img-render-title{
  font-size: 1.0625rem;
  margin-bottom: 1rem;
}


.project-gallery{
  img{
    max-width: 100%;
    margin-bottom: 1.5rem
  }
}

.project-related{
  h4{
    color: #7A7A7A;
    font-size: 1rem;
    span{
      display: block;
      margin-top: 1rem;
    }
  }
}

.project-related-item{
  margin-bottom: 1.5rem;
  a{
    display: block;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0,0,0);
    img{
      display: block;
      width: 100%;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      object-fit: cover;
    }
    &:hover{
      img{
        transform: scale(1.1);
      }
    }
  }
}

.main-project-detail {
  margin-top: 60px;
  @include media-breakpoint-up(lg) {
    margin-top: 120px;
  }
  .name {
    font-family: "pepper_monobold";
  }
  .content {
    margin: 0 15px;
    @include media-breakpoint-up(lg) {
      margin: 0 8.33333%;
    }
  }
  .profile {
    color: $black;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
    .project-desc {
      flex: 1;
      //text-align: justify;
      @include media-breakpoint-up(lg) {
        margin-right: 20px;
      }
    }
    .project-pros {
      flex: 1;
      @include media-breakpoint-up(lg) {
        margin-left: 20px;
      }
    }
  }
  .project-pros{
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0.8125rem;
    strong{
      display: inline-block;
      min-width: 120px;
    }
    li{
      margin-bottom: 1rem;
    }
  }

  .project-desc{
    font-size: 0.8125rem;
    line-height: 2;
  }

  .project-gallery {
    margin-top: 50px;
    @include media-breakpoint-up(lg) {
      margin-top: 100px;
    }
    .left {
      padding-right: 20px;
    }
    .right {
      padding-left: 20px;
    }
    img {
      margin-bottom: 0;
    }
    .row {
      margin-bottom: 50px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 100px;
      }
    }
  }

  .projects-nav {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .next-project {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.wwd-di {
  list-style: "-";
  padding-left: 10px;
}
.wwd-d {
  padding-left: 30px;
}

.ob{
  padding-left: 0;
  .ob-i{
    margin-bottom: 3.5rem;
  }
}